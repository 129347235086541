body#login {
	//background-color: $tn-red !important;

	#loginbox {
		.box {
			width:500px;
			margin:150px auto;
			h2 {
				background-color:$tn-blue;
				padding:20px 40px;
				margin-bottom: 0;
				color: #FFF;
				font-size: 24px;
			}
			.loginform {
				background-color: #FFF;
				padding:40px;
				border-bottom:solid 4px $tn-lightgrey-darken;
				a.forgot {
					display: block;
					color: $tn-lightgrey-darken;
					font-size: 14px;
					text-align: right;
					cursor: pointer;
				}
			}
			.extra {
				font-family: $font-regular;
				font-size: 14px;
				color: rgba(255,255,255,0.4);
				margin:20px 0;
			}
		}
	}	
}

