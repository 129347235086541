body#portal {
	background-image: url(../img/back-grass.jpg);
	background-repeat: repeat-x;
  	background-position: center top;
  	#navbar {
  		a {
  			padding:15px;
  			.fa {
  				margin-right:5px;
  			}
  			.fa-user {
				display: inline-block;
				text-align:center;
				background:#FFF;
				padding:5px;
				padding-top:7px;
				color:#bbb;
				width:30px;
				height:30px;
				border-radius:15px;
  			}
  		}	
  		li.dropdown ul {
  			position:absolute; 
  			display:none;
  			background:$tn-lightgrey;
  			z-index:9999999;
  			margin:0;
  			padding:10px 15px;
  			width:100%;
  			border-bottom:4px solid $tn-lightgrey-darken;
  			list-style-type:none;
  			min-width: 200px;
  			a {
  				display:block;
  				color:$tn-midgrey;
  				font-size: 16px; 
  				font-family: 'bariol_serif_regular';
  				text-decoration: none;
  				text-transform: none;
  				padding: 3px 10px;
  				&:hover {
  					background-color:transparent;
  					color:$tn-darkgrey;
  				}
  			}
  		}
  	}
	#mainnav {
		padding:40px 0;
		a {
			display: block;
		    width: 100%; height: 150px;
		    padding-top: 50px;
		    margin:15px 10px;
		    background-size: 100% 200%;
		    background-image: linear-gradient(to top, $tn-lightgrey-darken 50%, #FFF 50%);
		    transition: background-position .3s;
		    border-bottom: solid 4px $tn-lightgrey-darken;
		    text-align: center;
		    font-size:28px;
		    color:$tn-blue;
		}

		a:hover {
		    background-position: 0 100%;
		    color:#FFF;
		    text-decoration: none;
		}
	}

	#tabnav {
		padding-top:50px;
		.col-sm-2 {
			padding-left:6px;
			padding-right:6px;
			&:first-child {
				padding-left:15px;
			}
			&:last-child {
				padding-right:15px;
			}
			a {
				display:table;
				background-color:#FFF;
				color:$tn-blue;
				padding:5px;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
				word-wrap: break-word;
				text-align:center;
				line-height:1em;
				width:100%;
				border-bottom:solid 4px $tn-lightgrey-darken;
				span {
					display:table-cell;
					vertical-align:middle;
					height:70px;
				}
				&.active, &.active:hover {
					background-color:$tn-lightgrey;
					color:$tn-darkblue;
					border-bottom:solid 4px $tn-lightgrey;
				}
				&:hover {
					text-decoration:none;
					color:$tn-darkblue;
				}
			}
		}
	}

	#big-search {
		background-color: $tn-blue;
		padding:50px;
		.searchform {
			width:60%;
			margin:0 auto;
			position:relative;
			input[type=text] {
				padding:15px 30px;
				font-size: 24px;
				font-weight: normal;
				margin-bottom: 0;
				border:none;
				border-bottom: solid 4px $tn-lightgrey-darken;
			}
			input[type=submit] {
				font-family: 'FontAwesome';
				font-size:24px;
				position:absolute;
				padding:0;
				color:$tn-midgrey;
				top:11px;
				right:20px;
				width: 40px;
				height: 40px;
				border:none;
				background-color:transparent;
			}
		}
	}

	#content {
		background-color: $tn-lightgrey;
		p {
			margin: 8px 0 20px 0;
		}
		.article {
			color:#44596f;
			font-family:$font-serif;
			line-height:1.5em;
			.lastedit {
				color: rgba($tn-lightgrey, 1);
				font-family:$font-bold;
				font-size:14px;
				padding:15px 0 5px 0;
			}
			.intro {
				font-family: $font-bold;
				color:#000;
			}
			h1, h2, h3, h4, h5, h6 {
				color:$tn-red;
			}
			h1 { font-size:28px; }
			h2 { font-size:24px; }
			h3 { font-size: 20px; font-family: 'bariol_serif_regular'; color:#000;}
			h4 { font-size: 20px; font-family: 'bariol_serif_regular'; color:$tn-blue;}
			h5 { font-size: 18px; font-family: 'bariol_serif_regular';}
			h6 { font-size: 18px; color:#000;}
		}
		.breadcrumbs {
			ul {
				margin:0; padding:0;
				list-style-type:none;
				li {
					float:left;
					a {
						font-family: 'bariol_bold';
						display:block;
						padding:5px 15px;
						margin-right:15px;
						text-decoration:none;
						color: rgba($tn-midgrey,0.85);
						position:relative;
						top:-20px;
						// text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
						&:after, &:before {
							content: " "; 
							display: block; 
							width: 0; 
							height: 0;
							border-top: 24px solid transparent;           /* Go big on the size, and let overflow hide */
							border-bottom: 24px solid transparent;
							border-left: 12px solid rgba(255,255,255,0.4);
							border-radius:2px;
							position:absolute;
							right:-14px;
							top:-7px;
						}
						&:after {
							border-left: 12px solid $tn-lightgrey;
							right:-12px;

						}
						.fa-home {
							font-size:24px;
							margin-top:-3px;
						}
						&:hover{
							color: $tn-midgrey;
						}
					}
				}
			}
		}
		.searchresults {
			ul {
				margin:0; padding:0;
				list-style-type:none;
				li {
					padding-bottom:18px;
					a {
						text-decoration:none;
						color:$tn-blue;
						line-height:1em;
						span {
							font-family: 'bariol_bold';
							color:$tn-lightgrey-darken;
							text-transform:uppercase;
							font-size:12px;
						}
						small {
							display:block;
							color:$tn-lightgrey;
						}
					}
				}
			}
		}
		// DEPR 15-08-19
		#filterlist {
			.jplist-drop-down {
				box-shadow: none;
				border: none;
				text-shadow: none;
				background: #FFF !important;
				margin: 0;
				font-size: 14px;

				.jplist-dd-panel {
					&:after {
						top: 2px;
						font-size: 10px;
					}
				}
			}

			.jplist-label, .jplist-panel button {
				font-family: 'bariol_bold';
				box-shadow: none;
				border: none;
				border-radius: 2px;
				text-shadow: none;
				color: $tn-lightgrey-darken;
				margin: 20px 10px 0 0;
				background: #FFF;
			}
			.jplist-panel .jplist-pagination .jplist-current {
				background:$tn-lightgrey-darken !important;
				color:#FFF;
			}
			.jplist-panel button:hover {
				color: $tn-midgrey;
			}
			.jplist-panel .jplist-group {
				float:none;
				border:none;
			}
			.jplist-panel {
				label {
	                font-family: 'bariol_bold';
	                font-size: 16px;
	                text-transform: uppercase;
	                color:#000;
				}
			}
			.jplist-select {
				display:none;
			}
		}

		#belastingnieuws-container {
			#current-position {
				font-family: 'bariol_bold';
				border-radius: 2px;
				text-shadow: none;
				color: $tn-lightgrey-darken;
				margin: 20px 10px 0 0;
				background: #FFF;
				float: left;
				line-height: 30px;
				padding: 0px 10px;
				border: none;
			}

			.page-buttons button {
				@extend #current-position;

				&.active {
					background:$tn-lightgrey-darken !important;
					color:#FFF;
				}

				&:hover {
					background:$tn-lightgrey-darken !important;
					color:#FFF;
				}
			}

			.option-item {
				white-space: nowrap;

				label {
					float: none !important;
					font-family: 'bariol_bold';
					font-size: 16px;
					text-transform: uppercase;
					color: #000;
				}
			}
		}

        #tablefilter_wrapper .row:first-child {
			display:none;
		}

		#tablefilter {
			font-family: $font-serif;
			width:100% !important;

			#customSelect, #yearSelect {
				width:90px;
				background-color: #44596f;

			}

			strong {
				font-weight:normal;
			}

			th {
				font-family:$font-bold;
				background-color:$tn-midgrey;
				color:#FFF;
				padding:7px;
				font-weight:normal;

				&.function {
					width: 90px !important;
				}

				input[type=text] {
					padding:3px 0 0 3px;
					margin:0;
					color:$tn-midgrey;
				}

				&.vert {
					font-size:12px;
					text-align:center;
					height: 110px;
					width:40px !important;
	  				white-space: nowrap;

					div {
						transform: 
						/* Magic Numbers */
						translate(8px, 30px)
						/* 45 is really 360 - 45 */
						rotate(-90deg);
						width: 30px;
					}
				}
			}
			tr {
				cursor:default;		
			}
			tr.clickable-row {
				cursor:pointer
			}
			tr:hover {
				background-color:rgba($tn-lightgrey,0.5);
			}
			tr:nth-child(even) td {
				background-color:rgba($tn-lightgrey,0.2);
			}
			tr.hoofdfunctie td {
				background-color:$tn-red;
				font-family:$font-bold;
				color:#FFF;
				padding:7px 6px;
				text-transform:uppercase;
			}
			tr.functiegroep td, tr.functiegroep:hover td {
				background-color:rgba($tn-lightgrey,1);
				font-family:$font-bold;
				color:$tn-darkblue;
				padding: 10px 6px;
			}
			td, th {
				line-height:150%;
				padding:4px 6px;
				border-right: solid 2px rgba(255,255,255,0.3);
				&:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
					text-align:center;
				}
			}
			td:nth-child(7) {
				display:none; color:green;
			}
		}
		.linkbox {
			margin-bottom:12px;
			a {
				display:block;
				padding:6px 15px;
				border-radius:3px;
				background-color: rgba($tn-lightgrey,0.25);
				color:$tn-midgrey;
				&:hover {
					background-color: rgba($tn-lightgrey,0.4);
					text-decoration:none;
				}
				span {
					font-family: 'bariol_bold';
					color:$tn-blue;
					i.fa {
						margin-right:4px;
					}

				}
			}
		}
		.themas {
			.thema {
				position:relative;
				margin:15px 0;
				a {
					h2 {
						position:absolute;
						bottom:0;
						background-color:rgba($tn-blue, 0.8);
						padding:10px 25px;
						color:#FFF;
						margin-bottom:0;
						width:calc(100% - 30px);
						font-size:24px;
					}
				}
			}
		}

		.moreBlock {
				position:relative;
				margin:15px 0;
				a {
					h2 {
						position:absolute;
						bottom:0;
						background-color:rgba($tn-blue, 0.8);
						padding:10px 25px;
						color:#FFF;
						margin-bottom:0;
						width:calc(100% - 30px);
						font-size:24px;
					}
				}
			}
	}
}
