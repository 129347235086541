body {
}

.navbar-toggle {
	border: 2px solid white;
	padding: 8px 10px;
	margin-top: 17px;
}

.navbar-toggle .icon-bar {
	background-color: white;
	width: 19px;
}

.navbar-toggle .icon-bar + .icon-bar {
	margin-top: 3px;
}

#loginSmall {
	display: none;
}

#slider {
	position:relative;
	font-family: $font-bold;
	.cycle-slideshow {
		img {
			width:100%;
		}
	}
	.quote {
	    position: absolute;
	    top: 0;
	    bottom: 0;
	    width: 100%;
	    height: 30%;
	    margin: auto;
	    color:#FFF;
	    text-align:center;
	    z-index:100;
	    h1 {
	    	font-size:60px;
	    }
	}
}

#about {
	border-top: solid 1px $tn-lightgrey-darken;
	padding:150px 0; 
	background-color: #FFF;
	line-height:1.7em;
	h2 {
		color:$tn-red;
		margin-top:0;
		font-size:32px;
	}
}

footer {
	background-color: $tn-blue;
	padding:125px 0; 
	color:#FFF;
	a {
		color:#FFF;
	}
}


///////////////////  Responsive styling  ////////////////////


@media (max-width: 480px) { 
	#slider {
		.quote {
		    h1 {
		    	font-size:24px;
		    }
		}
	}
}

@media (max-width: 767px) { 
	#loginNormal {
		display: none;
	}

	#loginSmall {
		display: block;
	}

	#slider {
		.quote {
		    height:100%;
		    
		    h1 {
		    	font-size:22px;
		    }
		}
	}
	#about {
		padding:40px 0; 
	}

	footer {
		padding:50px 0; 
		.col-sm-4 {
			margin-bottom:25px;
		}
	}

}
@media (min-width: 768px) and (max-width: 991px) { 
	#slider {
		.quote {
		    h1 {
		    	font-size:40px;
		    }
		}
	}
}
@media (min-width: 992px) and (max-width: 1199px) { 
	#slider {
		.quote {
		    h1 {
		    	font-size:50px;
		    }
		}
	}
}

