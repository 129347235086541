@font-face {
    font-family: 'bariol_bold';
    src: url('../fonts/bariol_bold-webfont.eot');
    src: url('../fonts/bariol_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_bold-webfont.woff2') format('woff2'),
         url('../fonts/bariol_bold-webfont.woff') format('woff'),
         url('../fonts/bariol_bold-webfont.ttf') format('truetype'),
         url('../fonts/bariol_bold-webfont.svg#bariol_boldbold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bariol_icons';
    src: url('../fonts/bariol_icons_basic-webfont.eot');
    src: url('../fonts/bariol_icons_basic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_icons_basic-webfont.woff2') format('woff2'),
         url('../fonts/bariol_icons_basic-webfont.woff') format('woff'),
         url('../fonts/bariol_icons_basic-webfont.ttf') format('truetype'),
         url('../fonts/bariol_icons_basic-webfont.svg#bariol_icons_basicregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bariol_regular_italic';
    src: url('../fonts/bariol_regular_italic-webfont.eot');
    src: url('../fonts/bariol_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_regular_italic-webfont.woff2') format('woff2'),
         url('../fonts/bariol_regular_italic-webfont.woff') format('woff'),
         url('../fonts/bariol_regular_italic-webfont.ttf') format('truetype'),
         url('../fonts/bariol_regular_italic-webfont.svg#bariolregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bariol_regular';
    src: url('../fonts/bariol_regular-webfont.eot');
    src: url('../fonts/bariol_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_regular-webfont.woff2') format('woff2'),
         url('../fonts/bariol_regular-webfont.woff') format('woff'),
         url('../fonts/bariol_regular-webfont.ttf') format('truetype'),
         url('../fonts/bariol_regular-webfont.svg#bariol_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bariol_serif_regular_italic';
    src: url('../fonts/bariol_serif_regular_italic-webfont.eot');
    src: url('../fonts/bariol_serif_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_serif_regular_italic-webfont.woff2') format('woff2'),
         url('../fonts/bariol_serif_regular_italic-webfont.woff') format('woff'),
         url('../fonts/bariol_serif_regular_italic-webfont.ttf') format('truetype'),
         url('../fonts/bariol_serif_regular_italic-webfont.svg#bariol_serifregular_italic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bariol_serif_regular';
    src: url('../fonts/bariol_serif_regular-webfont.eot');
    src: url('../fonts/bariol_serif_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/bariol_serif_regular-webfont.woff2') format('woff2'),
         url('../fonts/bariol_serif_regular-webfont.woff') format('woff'),
         url('../fonts/bariol_serif_regular-webfont.ttf') format('truetype'),
         url('../fonts/bariol_serif_regular-webfont.svg#bariol_serif_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}