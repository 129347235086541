//sass --watch taxnav-total.scss:taxnav-styles.css --style compressed

// Bootstrap
@import "scss/bootstrap";

// Core variables and mixins
@import "scss/variables";
@import "scss/fonts";
@import "scss/global";
@import "scss/login";
@import "scss/home";
@import "scss/portal";
@import "scss/news";
@import "scss/vertical-menu";
@import "scss/print";