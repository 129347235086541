@media print {
	header, footer, #tabnav, .print-hide, .newsnav a, .share, input, #customSelect, .breadcrumbs, #cssmenu, .btn {
		display: none;
	}

	#content .box {
		border-bottom: none;
	}
}

@page {
	margin: 2cm 1cm;
}