body {
	font-family: $font-serif;
	font-size:16px;
  	background-color: $tn-lightgrey;
	color:$tn-midgrey;
}

h1,h2,h3,h4,h5,h6 {
	font-family: $font-bold;
}

b, strong {
	font-weight:normal;
	font-family: $font-bold;

}
label {
	color:$tn-lightgrey-darken;
	font-size:14px;
	font-weight:normal;
}
input[type=text],input[type=email],input[type=tel],input[type=password],textarea {
	border: solid 1px $tn-lightgrey;
	background-color: #FFF;
	padding:10px 20px;
	width:100%;
	margin:1px 0 15px 0;
}
input[type=submit] {
	font-size:20px;
	background-color:$tn-red;
	border: solid 1px $tn-red;
	text-align:center;
	color:#FFF;
	width:100%;
	padding:10px 20px;
	margin:1px 0 15px 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
img.img-responsive {
	display:inline-block;
}
.btn {
	color: #fff;
	background-color: $tn-red;
	border-radius: 0px;
	border:none;
	font-size:18px;
	text-align: center;
	display:block;
	padding: 10px 20px;
	margin: 10px 0 30px 0;
	&:hover {
		text-decoration:none;
	}
}

.btn-blue {
	@extend .btn;
	background-color: $tn-blue;
}
.btn-grey {
	@extend .btn;
	background-color: $tn-lightgrey-darken;
}
.btn-outline {
	@extend .btn;
	font-family: $font-bold;
	border: solid 2px $tn-lightgrey-darken;
	background-color: transparent;
	display:inline-block;
	color: $tn-lightgrey-darken;
	padding:5px 15px;
	&:hover {
		border: solid 2px $tn-midgrey ;
		color: $tn-midgrey;
	}
	&.inactive {
		border: solid 2px $tn-lightgrey !important;
		color: $tn-lightgrey !important;
		cursor:default;
	}
}
.font-bold { font-family: $font-bold; }
.font-icons { font-family: $font-icons; }
.font-italic { font-family: $font-italic; }
.font-regular { font-family: $font-regular; }
.font-serif { font-family: $font-serif; }
.font-serif-italic { font-family: $font-serif-italic; }


header#top {
	background-color: $tn-darkgrey;
	font-family: $font-bold;
	color:$tn-lightgrey;
	font-size:18px;
	text-transform:uppercase;
	.navbar {
		margin-bottom: 0;
		border:none;
		min-height: 65px;
		a {
			color:$tn-lightgrey;
			line-height: 36px;
			&:not(.navbar-brand):hover, &:not(.navbar-brand):focus {
				background-color:$tn-midgrey;
				color:#FFF;
			}

		}
		li.active > a {
			background-color:$tn-midgrey;
			color:#FFF;
		}
  		li.dropdown ul {
  			position:absolute; 
  			right:0;
  			display:none;
  			background:$tn-lightgrey;
  			z-index:9999999;
  			margin:0;
  			padding:10px 15px;
  			width:250px;
  			border-bottom:4px solid $tn-lightgrey-darken;
  			list-style-type:none;
  			input[type=text], input[type=password] {
  				background-color:#FFF;
  				color:$tn-darkgrey;
  			}
  			a {
  				display:block;
  				color:$tn-midgrey;
  				font-size: 16px; 
  				font-family: 'bariol_serif_regular';
  				text-decoration: none;
  				text-transform: none;
  				padding: 3px 10px;
  				&:hover {
  					background-color:transparent;
  					color:$tn-darkgrey;
  				}
  			}
  		}
	}
}

#content {
	padding:60px 0;
	.box {
	background-color:#FFF;
	border-bottom:solid 4px $tn-lightgrey-darken;
		.boxheader {
			background-color:$tn-lightgrey-darken;
			padding:15px 25px;
			margin-bottom: 0;
			color: #FFF;
			font-size: 24px;
		}
		.boxheader-red {
			@extend .boxheader;
			background-color:$tn-red;			
		}
		.boxheader-blue {
			@extend .boxheader;
			background-color:$tn-blue;			
		}
		.boxheader-midgrey {
			@extend .boxheader;
			background-color:$tn-midgrey;			
		}
		.boxheader-darkgrey {
			@extend .boxheader;
			background-color:$tn-darkgrey;			
		}
		.boxpadding {
			padding:20px;
			min-height:200px;
		}
	}

	.mostread .boxpadding {
			line-height:1.1em;
			 div {
				border-bottom: solid 1px rgba(227,228,227,0.5);
				margin-bottom: 10px;
				padding-bottom: 7px;
			}
		}

}

///////////////////  Responsive styling  ////////////////////


@media (max-width: 767px) { 
	#content {
		padding:10px 0 40px 0;
	}

	.row.no-gutters-mobile > [class^="col-"],
	.row.no-gutters-mobile > [class*=" col-"] {
	  padding-right: 0;
	  padding-left: 0;
	}

}
@media (min-width: 768px) and (max-width: 991px) { 

}
@media (min-width: 992px) and (max-width: 1199px) { 
}


