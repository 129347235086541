

#content {
	.newslist {
		p { 
				margin:0 !important;
		}
		.item {
			position:relative;
			&:before {
				content:"";
				width:calc(100% - 30px);
				margin-left:15px;
				border-bottom:solid 1px rgba($tn-lightgrey,.5);
			}
			a {
				color: #44596f;
				.newsphoto {

				}	
				.newsheader {
					overflow:hidden;
					max-height:111px;
					h3 {
						font-size:20px;
						margin-top: 12px;	
						padding-right:15px;
					}
				}		
				.date {
					color:$tn-lightgrey-darken;
					margin:10px 0 5px 0 !important;
				}
				.title {
					font-family:$font-serif;
					font-size:20px;
					color:$tn-darkblue;
					min-height:60px;
					line-height:1em;
					margin:0;
				}
				.meta {
					color:$tn-blue;
					margin:0;
				}
				.like {

				}
				.categories {

				}
				.fa-angle-right {
					font-size: 50px;
					line-height: 120px;
					color:$tn-lightgrey;
				}
			}
		}
		.readmore {
			text-align:right;
		}
		a.more-button {
			font-family: $font-bold;
			display:block;
			padding:15px 25px;
			color:$tn-midgrey;
			border-top:solid 1px rgba($tn-lightgrey,.5);
			&:hover {
				text-decoration:none;
				color:$tn-darkgrey;
			}
		}
	}
	.newsdetail {
		.meta {
			color:$tn-lightgrey-darken;
			font-size:12px;			
		}
		.intro {
			color:$tn-darkgrey;
			font-family: $font-bold;
			padding:20px 0;
		}
		.newsnav {
			clear:both;
			padding: 30px 0 5px 0;
		}	
	}
	.sidebar {
		.newslist {
			.item{
				.newsheader {
					padding:0;
					max-height:111px;
					h3 {
						font-size:15px;
						margin-top: 8px;
    					font-family: bariol_serif_regular;
    					line-height: 17px;
					}
				}		
			}
		}
	}
}

///////////////////  Responsive styling  ////////////////////


@media (max-width: 767px) { 
	#content {
		.newslist {
			.item{
				.date {
					margin:6px 0 3px 0 !important;
				}
				.newsheader {
					max-height:94px;
					h3 {
						font-size:18px;
						margin-top: 8px;				}
				}		
			}
		}
	}
}
@media (min-width: 768px) and (max-width: 991px) { 

}
@media (min-width: 992px) and (max-width: 1199px) { 
}
