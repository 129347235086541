              #cssmenu,
              #cssmenu ul,
              #cssmenu ul li,
              #cssmenu ul li a {
                margin: 0;
                padding: 0;
                border: 0;
                list-style: none;
                line-height: 150%;
                display: block;
                position: relative;
              }
              #cssmenu {
                width: 100%;
              }
              #cssmenu ul ul {
                display: none;
              }
              #cssmenu > ul > li.active > ul {
                display: block;
              }
              .align-right {
                float: right;
              }
              #cssmenu > ul > li > a {
                padding: 20px;
                border-top: 1px solid #e3e4e3;
                cursor: pointer;
                z-index: 2;
                font-family: 'bariol_bold';
                font-size: 16px;
                text-decoration: none;
                text-transform: uppercase;
                color:#000;
              }
              #cssmenu > ul > li > a:hover,
              #cssmenu > ul > li.active > a,
              #cssmenu > ul > li.open > a {
                color: #369ed9;
              }
              #cssmenu > ul > li.open > a {
                border-bottom: 1px solid #e3e4e3;
              }
              #cssmenu > ul > li:last-child > a,
              #cssmenu > ul > li.last > a {
                border-bottom: 1px solid #e3e4e3;
              }
              .holder {
                width: 0;
                height: 0;
                position: absolute;
                top: 0;
                right: 0;
              }
              .holder::after {
                display: block;
                position: absolute;
                content: "";
                width: 6px;
                height: 6px;
                right: 20px;
                z-index: 10;
                transform: rotate(135deg);
                transition: transform .3s ease-in-out;
              }
              .holder::after {
                top: 24px;
                border-top: 2px solid #000;
                border-left: 2px solid #000;
              }
              #cssmenu > ul > li > a:hover > span::after,
              #cssmenu > ul > li.active > a > span::after,
              #cssmenu > ul > li.open > a > span::after {
                border-color: #369ed9;
                transform: rotate(225deg);
              }
              #cssmenu ul ul li a {
                cursor: pointer;
                border-bottom: 1px solid #e3e4e3;
                padding: 5px 20px;
                z-index: 1;
                text-decoration: none;
                font-family:'bariol_serif_regular';
                font-size: 15px;
                color: #7d7d7d;
              }

              #cssmenu ul ul li a.active {
                border-left: 2px solid #369ed9;
              }


              #cssmenu ul ul li:hover > a,
              #cssmenu ul ul li.open > a,
              #cssmenu ul ul li.active > a {
                color: #000;
              }
              #cssmenu ul ul ul li a {
                padding-left: 30px;
                font-size: 14px;
              }
              #cssmenu > ul > li > ul > li:last-child > a,
              #cssmenu > ul > li > ul > li.last > a {
                border-bottom: 0;
              }
              #cssmenu > ul > li > ul > li.open:last-child > a,
              #cssmenu > ul > li > ul > li.last.open > a {
                border-bottom: 1px solid #e3e4e3;
              }
              #cssmenu > ul > li > ul > li.open:last-child > ul > li:last-child > a {
                border-bottom: 0;
              }
              #cssmenu ul ul li.has-sub > a::after {
                display: block;
                position: absolute;
                content: "";
                width: 5px;
                height: 5px;
                right: 20px;
                z-index: 10;
                top: 11.5px;
                border-top: 2px solid #7d7d7d;
                border-left: 2px solid #7d7d7d;
                transform: rotate(135deg);
              }
              #cssmenu ul ul li.has-sub > a:hover:after,
              #cssmenu ul ul li.active > a::after,
              #cssmenu ul ul li.open > a::after,
              #cssmenu ul ul li > a:hover::after {
                border-color: #000;
                transform: rotate(225deg);
              }

#cssmenu a.recente-ontwikkelingen {
  background-color: white;
  color: #ea4c61;
  font-family: "bariol_bold";
}