$tn-blue:				#369ed9;
$tn-darkblue:			#0b1124;
$tn-red:				#ea4c61;
$tn-lightgrey:			#e3e4e3;
$tn-lightgrey-darken:	#bbbbbb;
$tn-midgrey:			#44596f;
$tn-darkgrey:			#1e3042;

$font-bold: 'bariol_bold';
$font-icons: 'bariol_icons';
$font-italic: 'bariol_regular_italic';
$font-regular: 'bariol_regular';
$font-serif-italic: 'bariol_serif_regular_italic';
$font-serif: 'bariol_serif_regular';

